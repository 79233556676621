/**
 * @todo This needs a massive rewrite to bring it up to speed with the latest
 *    js code style and perf tweaks
 * @todo Make urls configurable
 */

import getConfig from 'next/config'
import AyuApiClient from 'services/api/AyuApiClient'
import { padNumber } from './date'

const { publicRuntimeConfig } = getConfig()
const cdnBaseUrl = publicRuntimeConfig.AWS_S3_BUCKET_BASE_URL

/**
 * Sizes are quite flexible.
 * 100x100 - will crop it to a 100px box
 * 100x - will resize it to 100px wide with auto height
 * And more but i'm too lazy to doc them
 *
 * @param {string} path
 * @param {string} size
 */
export const buildImageUrl = (path, size = 'undefined') => {
  return `https://${cdnBaseUrl}/assets/image/${size}/${path}`
}

/**
 * @param {object} asset
 */
export const buildImageUrlV2 = (asset, size = '') => {
  if (!asset || !asset.attributes) {
    return ''
  }
  if (size === '') {
    return `${cdnBaseUrl}/assets/image/${asset.id}/${asset.attributes.filename}`
  }
  return `${cdnBaseUrl}/${size}/assets/image/${asset.id}/${asset.attributes.filename}`
}

/**
 *
 * @param {string} path
 */
export const buildVideoUrl = (path) => {
  return `https://s3-eu-west-1.amazonaws.com/assets-southbank.enecdn.io/${path}`
}

export const prepareHtml = (string) => {
  return { __html: string }
}

/**
 *
 * @param {string} type
 * @param {object} data
 * @param {object} relationships
 * @returns {object}
 */
export const generatePostPayload = (type, data, relationships) => {
  const payload = {
    data: {
      type,
      attributes: { ...data },
    },
  }

  if (relationships) {
    payload.data.relationships = relationships
  }

  return payload
}

/**
 *
 * @param {int} timestamp
 */
export const formatDate = (timestamp, withTime = true) => {
  const a = new Date(timestamp * 1000)
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const year = a.getFullYear()
  const month = months[a.getMonth()]
  const date = a.getDate()
  const hour = a.getHours()
  const min = a.getMinutes()
  const sec = a.getSeconds()
  let time = `${date} ${month} ${year}`

  if (withTime) {
    time += ` ${padNumber(hour, 2)}:${padNumber(min, 2)}`
  }

  return time
}

/**
 *
 * @param {object} resource
 * @param {string} name
 * @param {mixed} fallback
 */
export const getAttrib = (resource, name, fallback = null) => {
  if (!resource || !resource.attributes || resource.attributes[name] === undefined) {
    return fallback
  }

  return resource.attributes[name]
}

export const ordinalSuffixOf = (i) => {
  const j = i % 10
  const k = i % 100
  if (j == 1 && k != 11) {
    return `${i}st`
  }
  if (j == 2 && k != 12) {
    return `${i}nd`
  }
  if (j == 3 && k != 13) {
    return `${i}rd`
  }
  return `${i}th`
}

/**
 *
 * @param {string} subscriptionType
 * @returns {string}
 */
export const getSubscriptionTypeLabel = (subscriptionType) => {
  switch (subscriptionType) {
    case 'standard':
      return 'AYU'

    case 'plus':
      return 'AYU+'

    case 'fund':
      return 'Team'

    case 'sfo':
      return 'Single Family Office'

    case 'sp':
      return 'Service Provider'

    default:
      return ''
  }
}

/**
 * Debounce call to the given function by a specific time.
 *
 * The callback is invoked only when the timeout is reached,
 * and the timeout itself is reset every time when the returned
 * wrapper function is called.
 *
 * @param {function(...): void} func
 * @param {number} time
 * @returns {(function(): void)}
 */
export function debounce(func, time) {
  return function() {
    const element = this
    const args = arguments

    clearTimeout(element.timeout)
    element.timeout = setTimeout(function() {
      func.apply(element, Array.from(args))
    }, time || 300)
  }
}

/**
 * @param {*} attributes
 * @return {*} object {isValid: bool, error: string}
 */
export const validateEventConfig = async (attributes) => {
  const response = await AyuApiClient.post(`events/verify-event-config`, {
    attributes,
  })
  return response.data
}
