import axios from 'axios'
import responseNormalizer from './interceptors'

const BASE_ENDPOINT = '/api/'

export class AyuApiClient {
  constructor(baseUrl) {
    this.axios = axios.create()

    this.axios.interceptors.request.use((request) => {
      request.headers['X-Req-Origin'] = 'admin'
      return request
    })
    this.axios.interceptors.response.use(
      responseNormalizer.successResponseNormalizer,
      responseNormalizer.errorResponseNormalizer
    )
    axios.interceptors.response.use((response) => response, responseNormalizer.formValidationErrors)

    this.baseUrl = baseUrl
    this.authToken = null
  }

  includesToUrl(includes = []) {
    return includes.join(',')
  }

  filtersToUrl(includes = []) {
    return includes.join(';')
  }

  getQueryString(params = {}) {
    const parts = []
    if (params.includes && params.includes.length > 0) {
      parts.push(`include=${this.includesToUrl(params.includes)}`)
    }

    if (params.filters && params.filters.length > 0) {
      parts.push(`filters=${this.filtersToUrl(params.filters)}`)
    }

    if (params.page && params.page >= 1) {
      parts.push(`page=${params.page}`)
    }

    // Include soft-deleted resources
    if (params.deleted) {
      parts.push('deleted=1')
    }

    return parts.join('&')
  }

  getResourcePath(resource, params) {
    if (params.id) {
      return `${resource}/${params.id}`
    }

    return resource
  }

  setAuthToken(authToken) {
    this.authToken = authToken
  }

  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl + BASE_ENDPOINT
  }

  addAuthorizationHeader(config = {}) {
    if (this.authToken) {
      return {
        ...config,
        headers: {
          Authorization: `Bearer ${this.authToken}`,
          ...config.headers,
        },
      }
    }

    return config
  }

  addRequestConfig(config) {
    return this.addAuthorizationHeader(config)
  }

  get(resource, params = {}, config = {}) {
    const queryString = this.getQueryString(params)
    const resourcePath = this.getResourcePath(resource, params)

    return this.axios.get(
      `${this.baseUrl}${resourcePath}?${queryString}`,
      this.addRequestConfig(config)
    )
  }

  post(resource, payload = {}, config = {}) {
    return this.axios.post(`${this.baseUrl}${resource}`, payload, this.addRequestConfig(config))
  }

  delete(resource, params = {}, config = {}) {
    const resourcePath = this.getResourcePath(resource, params)
    return this.axios.delete(`${this.baseUrl}${resourcePath}`, this.addRequestConfig(config))
  }

  patch(resource, params = {}, payload = {}, config = {}) {
    const queryString = this.getQueryString(params)
    const resourcePath = this.getResourcePath(resource, params)
    return this.axios.patch(
      `${this.baseUrl}${resourcePath}?${queryString}`,
      payload,
      this.addRequestConfig(config)
    )
  }
}

export default new AyuApiClient(BASE_ENDPOINT)
