import { SubmissionError } from 'redux-form'

import { parseResponseResources } from 'lib/apiFormatter'

const getServiceValidationErrors = (errors = []) => {
  let normalizedErrors = {}
  errors.forEach(({ meta }) => {
    if (meta && meta.errors) {
      normalizedErrors = {
        ...normalizedErrors,
        ...meta.errors,
      }
    }
  })

  return normalizedErrors
}

export const successResponseNormalizer = (response) => {
  // We want full body including includes

  const body = response.data
  const transformed = parseResponseResources(body)
  return {
    ...response,
    data: transformed,
    meta: body.meta,
  }
}

export const errorResponseNormalizer = (error) => {
  const data = error.response.data.errors
  return Promise.reject({
    ...error.response,
    data,
  })
}

export const formValidationErrors = (error) => {
  if (error.config.form) {
    const { data, status } = error

    // TODO: temporary solution for handling general form errors
    if (status === 500 || status === 404) {
      return Promise.reject(new SubmissionError({ _error: 'Error occurred.' }))
    }

    if (status === 400) {
      const { errors } = data
      return Promise.reject(new SubmissionError(getServiceValidationErrors(errors)))
    }
  }

  return Promise.reject(error)
}
