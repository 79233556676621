import React from 'react'
import PropTypes from 'prop-types'
import RaisedButton from 'material-ui/RaisedButton'

const Button = ({ disabled, label, onClick, primary, href, className, style }) => {
  return (
    <RaisedButton
      className={className}
      label={label}
      disabled={disabled}
      onClick={onClick}
      href={href}
      primary={primary}
      style={style}
    />
  )
}

Button.defaultProps = {
  disabled: false,
  label: 'Save',
  onClick: () => {},
  primary: false,
}

Button.propTypes = {
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  href: PropTypes.string,

  onClick: PropTypes.func.isRequired,
}

export default Button
