const excludeAttributes = (resource) => {
  if (resource.attributes) {
    const { attributes } = resource
    delete resource.attributes

    return {
      ...resource,
      ...attributes,
    }
  }

  return resource
}

/**
 * This is the top  level entry function.
 * Throw your resource response into it and it will take care of the rest.
 *
 * It ALWAYS returns a collection of resources, even for singular responses
 *
 * @param {object} response
 * @return {object|object[]}
 */
export const parseResponseResources = (response) => {
  if (!response.data) {
    return null
  }

  if (response.included === undefined) {
    response.included = []
  }

  if (response.data.length > 0) {
    // Responses with multiple resources are treated as collections
    return processCollectionIncludes(response.data, response.included)
  }

  // Other responses must be singular items.
  return processItemIncludes(response.data, response.included)
}

/**
 * Loops through all resources in the given response
 * and processes each one of them individually
 *
 * @param {object[]} collection
 * @param {object[]} included
 * @return {object[]}
 */
export const processCollectionIncludes = (collection, included) => {
  if (!collection) {
    // Nothing.
    return []
  }

  const resources = collection.map((resource) => {
    // Denormalize each resource in this response
    return processItemIncludes(resource, included, collection)
  })

  return resources
}

/**
 * Attempts to resolve all relationships and dependencies for the given resource
 * This function is recursive and nested relationships will be properly resolved
 *
 * @param {object} item
 * @param {object[]} included
 * @return {object}
 */
export const processItemIncludes = (item, included, collection = []) => {
  if (!item) {
    // Quit straight away if nothing was given
    return null
  }

  if (item.__processed) {
    return item
  }
  item.__processed = true

  if (!item.relationships) {
    // Some resources don't have any relaitonships.
    // Create empty attribute to avoid undefined errors in the future
    item.relationships = {}

    return item
  }

  const mergedIncluded = [...included, ...collection]

  Object.keys(item.relationships).forEach((name, index) => {
    // Process each relationship individually
    const details = item.relationships[name]

    if (!details) {
      // Relationship could be null
      return
    }

    const { data } = details

    if (!data) {
      if (!details.attributes) {
        // Data could be null!
        // In which case remove the relationship key entirely
        delete item.relationships[name]
      }
      return
    }

    if (data.length > 0) {
      // If it's an array - do this
      const group = []

      data.forEach((data2) => {
        // Resolve dependencies for each individual resource as well
        let subItem = findInclude(data2.type, data2.id, mergedIncluded)
        subItem = processItemIncludes(subItem, mergedIncluded)
        group.push(subItem)
      })

      item.relationships[name] = group
    } else {
      // Single item
      item.relationships[name] = findInclude(data.type, data.id, mergedIncluded)
      item.relationships[name] = processItemIncludes(item.relationships[name], mergedIncluded)
    }
  })

  return item
}

/**
 * Finds a related resource within the included array based on its type and id
 *
 * @param {string} type
 * @param {item} id
 * @param {object[]} included
 * @return {object}
 */
export const findInclude = (type, id, included = []) => {
  if (included.length === 0) {
    return null
  }

  let item = null
  included.some((include) => {
    if (include.type === type && ~~include.id === ~~id) {
      item = include
      return true
    }
  })

  return item
}
