const addHeaders = (headers) => (config) => {
  return {
    ...config,
    headers: {
      ...config.headers,
      ...headers,
    },
  }
}

export default {
  addHeaders,
}
