const routes = (module.exports = require('next-routes')())

routes
  .add('/', 'applicationsList')
  // .add('blog', '/blog/:id', 'post')
  // .add('/:noname/:lang(en|es)/:wow+', 'complex')

  .add('/application/:id(\\d+)', 'applicationsEdit')
  .add('/applications', 'applicationsList')
  .add('/applications/:page(\\d+)', 'applicationsList')
  .add('/event/:id(\\d+)', 'eventsEdit')
  .add('/events', 'eventsList')
  .add('/events/:page(\\d+)', 'eventsList')
  .add('/events/create', 'eventsCreate')
  .add('/benefit/:id(\\d+)', 'benefitsEdit')
  .add('/benefits', 'benefitsList')
  .add('/benefits/:page(\\d+)', 'benefitsList')
  .add('/benefits/create', 'benefitsCreate')
  .add('/user/:id(\\d+)', 'usersEdit')
  .add('/users', 'usersList')
  .add('/users/:page(\\d+)', 'usersList')
  .add('/invite/:id(\\d+)', 'invitesEdit')
  .add('/invites', 'invitesList')
  .add('/invites/create', 'invitesCreate')
  .add('/invites/:page(\\d+)', 'invitesList')
  .add('/authorizations', 'authorizationsList')
  .add('/authorizations/:page(\\d+)', 'authorizationsList')
  .add('/tag/:id(\\d+)', 'tagsEdit')
  .add('/tags', 'tagsList')
  .add('/tags/:page(\\d+)', 'tagsList')
  .add('/tags/create', 'tagsCreate')
  .add('/stats/network/:page(\\d+)', 'networkStats')
  .add('/fund/agreements', 'fundAgreements')
  .add('/fund/list', 'fundList')

/*
   <IndexRoute component={ApplicationsList} />
        <Route path='application/:id' component={ApplicationsEdit} />
        <Route path='applications' component={ApplicationsList} />
        <Route path='applications/:page' component={ApplicationsList} />
        <Route path='event/:id' component={EventsEdit} />
        <Route path='events' component={EventsList} />
        <Route path='events/create' component={EventsCreate} />
        <Route path='events/:page' component={EventsList} />
        <Route path='benefit/:id' component={BenefitsEdit} />
        <Route path='benefits' component={BenefitsList} />
        <Route path='benefits/create' component={BenefitsCreate} />
        <Route path='benefits/:page' component={BenefitsList} />
        <Route path='user/:id' component={UsersEdit} />
        <Route path='users' component={UsersList} />
        <Route path='users/:page' component={UsersList} />
        <Route path='invite/:id' component={InvitesEdit} />
        <Route path='invites' component={InvitesList} />
        <Route path='invites/create' component={InvitesCreate} />
        <Route path='invites/:page' component={InvitesList} />
        <Route path='authorizations' component={AuthorizationsList} />
        <Route path='authorizations/:page' component={AuthorizationsList} />


        */
