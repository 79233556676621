// @flow

import { ordinalSuffixOf } from './utils'

export const padNumber = (number: number, digits: number = 2): string => {
  const full = `0000000000${number}`
  return full.substr(-digits)
}

export const formatDefaultDate = (date) => {
  if (!(date instanceof Date)) {
    date = new Date(date * 1000)
  }

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const day = date.getDate()
  const monthIndex = date.getMonth()
  const year = date.getFullYear()

  return `${ordinalSuffixOf(day)} ${monthNames[monthIndex]}`
}

export const mapMonthIndexToName = (index: number): string => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  return monthNames[index]
}

export const mapWeekIndexToName = (index: number): string => {
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  return dayNames[index]
}

/**
 * 01 April 2018
 */
export const formatLongDate = (date: Date | number): string => {
  if (!(date instanceof Date)) {
    date = new Date(date * 1000)
  }

  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()

  return `${padNumber(day)} ${mapMonthIndexToName(month)} ${year.toString()}`
}

export const formatShortDate = (date: Date | number): string => {
  if (!(date instanceof Date)) {
    date = new Date(date * 1000)
  }

  return `${mapWeekIndexToName(date.getDay())} ${ordinalSuffixOf(date.getDate())}`
}

export const formatNumericDate = (date: Date | number): string => {
  if (!(date instanceof Date)) {
    date = new Date(date * 1000)
  }

  return `${padNumber(date.getDate())}/${padNumber(date.getMonth() + 1)}/${date.getFullYear()}`
}

export const getFirstOfMonths = (count: number = 6, offset: number = 1): string[] => {
  const dates = []

  const now = new Date()

  for (let _A = offset; _A < count + offset; _A++) {
    const date = new Date(now.getFullYear(), now.getMonth() + _A, 1)
    dates.push(formatLongDate(date))
  }

  return dates
}

export const getLastOfMonths = (count: number = 6, offset: number = 1): string[] => {
  const dates = []

  const now = new Date()

  for (let _A = 1 + offset; _A < count + 1 + offset; _A++) {
    const date = new Date(now.getFullYear(), now.getMonth() + _A, 0)
    dates.push(formatLongDate(date))
  }

  return dates
}
